<template>
    <div>
        <AppbarFixed :hasBackButton="true" title="Edit Project" actionBootstrapIcon="trash" @actionClick="deleteProject"/>
        <EditProjectForm :project="project"/>
        <div class="bottom-margin-box"></div>
        <BottomNavBar :currentIndex="1" @updateIndex="updateIndex"/>
    </div>
</template>

<script>
import AppbarFixed from '../../components/general/AppbarFixed.vue';
import EditProjectForm from '../../components/edit_project/EditProjectForm.vue';
import BottomNavBar from '../../components/general/BottomNavBar.vue';

import { db } from '../../db';

export default {
    components: {
        AppbarFixed,
        EditProjectForm,
        BottomNavBar,
    },
    data() {
        return {
            project: null,
        }
    },
    created() {
        this.project = this.$route.params.project;
    },
    methods: {
        updateIndex(index) {
            if (index == 4) {
                // means its create project
                this.$router.push("/create/project");
                index = 1;
            } else {
                this.$router.push("/");
            }
            this.$store.commit("updateTabIndex", index);
        },
        deleteProject() {
            const shouldDelete = confirm("Are you sure you want to permanently delete this project?");
            if (!shouldDelete) return;
            
            db.collection("projects").doc(this.project.id).delete()
            .then(() => {
                console.log("Successfully deleted project.");
                this.$router.go(-2);
            })
            .catch((err) => {
                console.log(err);
                alert("Error deleting project, please try again.");
            })
        }
    }
}
</script>

<style scoped>

</style>
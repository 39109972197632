<template>
    <div class="mt-3">
        <h1>Edit Project</h1>
        <div v-if="editingGallery == 9.2">
            <EditMedia :project="project" @editMediaComplete="editMedia"/>
        </div>
        <div v-else>
            <b-container>
                <b-form @submit.prevent="onSubmit">
                    <b-form-group id="title-input" class="mb-4 px-2">
                        <div class="title-label-row">
                            <label for="input-1" class="form-label text-start">Project Title<span style="color:red"> *</span></label>

                            <span>{{titleLength}}/{{titleMax}}</span>
                        </div>
                        <b-form-input
                            id="input-1"
                            v-model="form.title"
                            type="text"
                            placeholder="Project Title"
                            size="lg"
                            autocomplete="off"
                            aria-describedby="input-1-live-feedback"
                        >
                        </b-form-input>
                    </b-form-group>
                    <b-form-group id="description-input" class="mb-2 px-2">
                        <div class="title-label-row">
                            <label for="description" class="form-label text-start">Project Description<span style="color:red"> *</span></label>

                            <span>{{descriptionLength}}/{{descriptionMax}}</span>
                        </div>
                        <b-form-textarea
                            id="description"
                            size="lg"
                            v-model="form.description"
                            placeholder="Description"
                            rows="3"
                            max-rows="6"
                            no-resize
                            autocomplete="off"
                            ></b-form-textarea>
                    </b-form-group>
                    <div class="w-100 px-2 py-2">
                        <div class="row">
                            <div class="col-9 text-start">
                                <label for="buyMyProject" class="form-label text-start">Enable Buy My Project</label>
                            </div>
                            <div class="col">
                                <div class="form-check form-switch d-flex flex-row-reverse">
                                    <input v-if="project.buyProjectOn" class="form-check-input parsill-switch" type="checkbox" id="buyMyProjectOn" @click="updateBuyMy" checked>
                                    <input v-else class="form-check-input parsill-switch" type="checkbox" id="buyMyProjectOn" @click="updateBuyMy">
                                </div>
                            </div>
                        </div>
                    </div>
                    <b-form-group v-if="buyProjectOn == true" id="buy-my-input" class="mb-4 px-2">
                        <b-form-input
                            id="buy-my"
                            v-model="form.purchaseLink"
                            type="url"
                            placeholder="Enter a link to your store"
                            size="lg"
                            autocomplete="off"
                            aria-describedby="input-1-live-feedback"
                        >
                        </b-form-input>
                    </b-form-group>
                    <!-- 
                    -->

                    <!-- <hr> -->
                    <div class="w-100 px-2 py-2">
                    <div class="row">
                        <div class="col-9 text-start">
                            <label for="buyMyProject" class="form-label text-start">Enable <strong>How-To</strong> Button</label>
                        </div>
                        <div class="col">
                            <div class="form-check form-switch d-flex flex-row-reverse">
                                <input v-if="useHowTo == true" class="form-check-input parsill-switch" type="checkbox" id="howToOn" @click="updateHowTo" checked>
                                <input v-else class="form-check-input parsill-switch" type="checkbox" id="howToOn" @click="updateHowTo" >
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="useHowTo == true">
                    <b-form-group id="buy-my-input" class="mb-4 px-2">
                        <div class="title-label-row">
                            <label for="howToDescription" class="form-label text-start">Description</label>

                        </div>
                        <b-form-textarea
                            id="howToDescription"
                            size="lg"
                            v-model="howToDescription"
                            placeholder="Describe your process"
                            rows="3"
                            max-rows="6"
                            no-resize
                            autocomplete="off"
                            ></b-form-textarea>
                    </b-form-group>
                    <p>or</p>
                    <b-form-group id="buy-my-input" class="mb-4 px-2">
                        <div class="title-label-row">
                            <label for="howToDescription" class="form-label text-start">New How-to Media</label>
                        </div>
                        <input type="file" class="form-control" id="howToMedia" @change="howToUpload($event.target.files)" multiple accept="image/* image/pdf">
                    </b-form-group>
                    <hr>
                </div>

                    <!-- <div class="d-flex justify-content-between my-1 mx-2 align-items-center">
                        <span class="form-label text-start">Image Gallery</span>

                    </div> -->

                    <div class="w-100 px-2">
                        <b-row class="pt-2">
                            <label for="mainImage" class="form-label text-start">New Cover Image</label>
                        </b-row>
                        <input type="file" class="form-control" id="mainImage" @change="filesChange($event.target.files, true)">
                        <label for="mainImage" class="sub-label text-start">This is the lead image people will see first</label>


                        
                        
                    </div>

                    <!-- <b-button class="my-3 parsillBlueBg" @click="editMedia">Edit Other Media</b-button> -->

                    <!-- <div class="d-flex flex-wrap justify-content-around">
                        <div class="shadow border border-dark rounded bold gallery-add mx-2 my-1 center-text" @click="createNewProject">
                            +
                        </div>
                        <div class="gallery-img mx-2 my-1"></div>
                        <div v-for="item in project.media" :key="item" class="gallery-img mx-2 my-1"></div>
                    </div> -->
                    <div class="w-100 px-2">
                        <b-row class="pt-2">
                            <label for="otherMedia" class="form-label text-start">New Project Media</label>
                        </b-row>
                        <input type="file" class="form-control" id="otherMedia" multiple @change="filesChange($event.target.files, false)">
                        <!-- <label for="otherMedia" class="sub-label text-start">This can be as many photos or videos as needed</label> -->
                    </div>

                    <hr>
                    <div class="w-100 px-2">
                        <b-row class="pt-2">
                            <label for="categorySelect" class="form-label text-start">Category<span style="color:red"> *</span></label>
                        </b-row>
                        <select class="form-select form-control form-control-lg" id="categorySelect" @change="updateCategory">
                            <option v-for="category in categories" :key="category.id" :value="category.id" :selected="category.id == form.category.id">{{category.title}}</option>
                        </select>
                    </div>
                    <b-spinner v-if="loading==true" class="mt-5 mb-3"></b-spinner>
                    <b-button v-else class="mt-5 mb-3" variant="primary" size="lg" @click="editProject">Save Project</b-button>
                </b-form>
                
                
            </b-container>
        </div>
    </div>
</template>

<script>
import EditMedia from '../../views/nested/EditMedia.vue';
import { db, storage } from '../../db';
import { mapState } from 'vuex';

export default {
    components: {
        EditMedia,
    },
    data() {
        return {
            title: null,
            description: null,
            form: {
                title: null,
                description: null,
                purchaseLink: null,
                toolsUsed: [],
                mainImage: null,
                otherMedia: [],
                category: null,
            },
            toolInput: "",
            buyProjectOn: false,
            loading: false,
            useLinksOn: false,
            editingGallery: false,
            useHowTo: false,
            howToDescription: "",
            titleMax: 30,
            descriptionMax: 100,
            howToFiles: [],
        }
    },   
    watch: {
        title(newTitle, oldTitle) {
            if (newTitle.length > this.titleMax) {
                
                this.$nextTick(() => {
                    this.title = oldTitle;
                })
                
            }
            else {
                this.form.title = newTitle;
            }
        },
        description(newDescription, oldDescription) {
            if (newDescription.length > this.descriptionMax) {
                
                this.$nextTick(() => {
                    this.description = oldDescription;
                })
                
            }
            else {
                this.form.description = newDescription;
            }
        },
    },
    mounted() {
        // this.form.category = this.categories[0];
        this.form.title = this.project.title;
        this.form.description = this.project.description;
        this.form.purchaseLink = this.project.purchaseLink;
        this.form.toolsUsed = this.project.toolsUsed;
        this.form.category = this.categories.filter(category => this.project.categoryId == category.id)[0];
        this.useLinksOn = this.project.useLinksOn ?? false;
        this.buyProjectOn = this.project.buyProjectOn ?? false;
        this.useHowTo = this.project.howToLength ?? 0 > 0;
        if (this.project.howToDescription) {
            this.howToDescription = this.project.howToDescription;
        }
    },
    props: {
        project: null,
    },
    methods: {
        howToUpload(files) {
            if (files.length > 0) {
                for (let i=0; i < files.length; i++) {
                    const f = files[i];
                    if (f.type.includes("video")) {
                        alert("How-to files must be images or PDFs");
                        this.$nextTick(() => {
                            document.getElementById("howToMedia").value = null;
                        });
                        return;
                    }
                    
                }
                this.howToFiles = files;
            }
        },
        updateHowTo() {
            this.useHowTo = !this.useHowTo;
        },
        editMedia() {
            this.editingGallery = !this.editingGallery;
        },
        removeTool(tool) {
            const shouldDelete = confirm(`Delete ${tool} from tools?`);

            if (!shouldDelete) return;

            const index = this.form.toolsUsed.indexOf(tool);
            if (index > -1) {
                this.form.toolsUsed.splice(index, 1);
            }
        },
        toolLinkIdentifier(tool, index) {
            return `${tool}${index}`;
        },
        toolLink(tool, index) {
            var linkField = document.getElementById(this.toolLinkIdentifier(tool, index));
            return linkField.value;
        },
        updateUseLinks() {
            this.useLinksOn = !this.useLinksOn;
        },
        updateBuyMy() {
            this.buyProjectOn = !this.buyProjectOn;
        },
        updateCategory() {
            var selectedId = document.getElementById("categorySelect").value;
            var relevantCategory = this.categories.filter(category => category.id == selectedId)[0];
            this.form.category = relevantCategory;
        },
        async editProject() {
            var validationMessages = [];
            if (this.form.title == null) validationMessages.push("No Title");
            if (this.form.description == null) validationMessages.push("No Description");
            if (this.buyProjectOn == true && this.form.purchaseLink == null) validationMessages.push("No Purchase Link");
            // if (this.form.mainImage == null) validationMessages.push("No Cover Image");
            if (this.form.category == null) validationMessages.push("No Category");

            if (validationMessages.length > 0) {
                alert("Please fix the following errors:\n" + validationMessages.join("\n"));
                return;
            }

            // all good, lets upload

            this.loading = true;

            // var media = [];
            // for (var i=0; i < this.form.otherMedia.length; i++) {
            //     media.push("media" + i.toString());
            // }

            // var toolLinks = [];
            // for (var i=0; i < this.form.toolsUsed.length; i++) {
            //     var tool = this.form.toolsUsed[i];

            //     var link = this.toolLink(tool, i) ?? "";
            //     toolLinks.push(link);
            // }

            let categoryTitle = this.form.category.title;
            let categoryId = this.form.category.id;

            var data = {
                title: this.form.title.trim(),
                creatorId: this.parsillUser.id,
                creator: this.parsillUser.username,
                // media: media,
                // toolsUsed: this.form.toolsUsed,
                // toolLinks: toolLinks,
                // useLinksOn: this.useLinksOn,
                comments: [],
                // created: new Date(),
                categoryId: categoryId,
                category: categoryTitle,
                description: this.form.description,
                buyProjectOn: this.buyProjectOn,
                purchaseLink: this.form.purchaseLink,
            };

            if (this.howToDescription != "") {
                data.howToDescription = this.howToDescription;
            }
            if (this.howToFiles.length > 0) {

                data.howToLength = this.howToFiles.length;

                const projectRef = storage.ref("projects/" + this.project.id);

                for (let i=0; i < this.howToFiles.length; i++) {
                    const f = this.howToFiles[i];
                    const howToRef = projectRef.child(`/howTo/${i}`);
                    try {
                        await howToRef.delete();

                    } catch (err) {
                        console.log("Error deleting howToMedia");
                        console.log(err);
                    }

                    try {
                        howToRef.put(f)
                        .then(() => {
                            console.log(`uploaded How To Image ${i}`);
                        })
                        
                    }
                    catch (err) {
                        console.log(err);
                        alert("Error uploading how to image");
                        this.loading = false;
                        return;
                    }
                }
            }

            if (this.form.mainImage) {
                storage.ref(`/projects/${this.project.id}/main`).put(this.form.mainImage)
                .then(() => {
                    console.log("Successfully uploaded new cover image");
                })
                .catch((err) => {
                    console.log(err);
                    alert("Something went wrong uploading the new cover image.")
                });
            }

            if ((this.form.otherMedia?.length ?? 0) > 0) {
                const otherRef = storage.ref(`/projects/${this.project.id}/other/`);

                const allFiles = await otherRef.listAll();

                console.log(allFiles.length);

                for (let i=0; i < allFiles.items.length; i++) {
                    const file = allFiles.items[i];
                    console.log("deleting a file");
                    await file.delete();
                }
                

                for (var i=0; i < this.form.otherMedia.length; i++) {
                    let name = `media${i}`;
                    let file = this.form.otherMedia[i];
                    storage.ref(`/projects/${this.project.id}/other/${name}`).put(file).catch((err) => alert("Error uploading other media: " + `${err}`));
                    
                }
            }

            // first create the document
            db.collection("projects").doc(this.project.id).update(data)
            .then(() => {
                console.log("Successfully updated the project");
                this.$router.go(-1);
            }) 
            .catch(err => {
                alert("Error editing project: " + `${err}`);
                this.loading = false;
                return;
            });
        },
        onEdit(value) {
            // returned value is what goes into the form field

            if (value.includes(",")) {
                // Add to list and clear
                var formattedValue = value.replace(",", "");
                this.form.toolsUsed.push(formattedValue);
                return "";
            }

            return value;
        },
        enterTool() {
            console.log("hello");
            if (this.toolInput == "" || this.toolInput == null) return;

            console.log("hello again!");

            this.form.toolsUsed.push(this.toolInput.trim());
            this.toolInput = "";
        },
        filesChange(files, isMain) {
            console.log(files[0]);
            if (isMain == true) {
                if (files.length > 0) {
                    // alert("assigned new mainImage");
                    this.form.mainImage = files[0];
                }
            } else {
                if (files.length > 0) {
                    this.form.otherMedia = files;
                }
            }
        },
        loadGallery() {
            storage.ref(`/projects/${this.project.id}/`)
        }
    },
    computed: {
        titleLength() {
            return this.form.title?.length ?? 0;
        },
        descriptionLength() {
            return this.form.description?.length ?? 0;
        },
        toolsChunked: function () {
            var chunks = [];

            var rowIndex = 0;
            for (var i=0; i < this.form.toolsUsed.length; i++) {
                if (rowIndex == 0) {
                    chunks.push([]);
                }

                var tool = this.form.toolsUsed[i]
                chunks[chunks.length - 1].push(tool);

                if (rowIndex == 2) {
                    rowIndex = 0;
                } else {
                    rowIndex++;
                }
            }
            return chunks;
        },
        ...mapState({
            parsillUser: (state) => state.parsillUser,
            categories: (state) => state.categories,
        }),
        hasHowTo() {
            console.log((this.project.howToDescription ?? false) || (this.project.howToLength ?? 0 > 0));
            
            return (this.project.howToDescription ?? false) || (this.project.howToLength ?? 0 > 0);
        }
    },
}
</script>

<style scoped>
    .btn-primary {
        background-color: #09256e !important;
    }

    .form-label {
        font-size: larger;
    }

    .sub-label {
        font-size: medium;
    }

    .parsill-switch {
        width: 50px;
        height: 25px;
    }

    .gallery-add {
        background-color: white;
        width: 30vw;
        height: 30vw;
    }

    .gallery-img {
        background-color: grey;
        width: 30vw;
        height: 30vw;
    }

    .center-text {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .bold {
        font-weight: bold;
        font-size: 2em;
    }

    .title-label-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

</style>
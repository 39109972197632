<template>
    <div class="expanded-viewport no-overflow">
        <div class="mt-5" v-if="loading == true">
            <b-spinner/>
        </div>
        <div v-else>
            <div class="w-100 px-2 mt-4">
                <input type="file" class="form-control" id="otherMedia" multiple @change="filesChange($event.target.files)">
                <label for="otherMedia" class="sub-label text-start">Add one or more files above. Tap existing images below to delete.</label>
            </div>
            <b-container>
                
                <b-row class="p-3" v-for="(row, index) in imagesChunked" :key="index">
                    <b-col>
                        <ImageCarouselCard :imgUrl="row[0]" :contentType="contentTypesChunked[index][0]" @viewImage="deleteMedia(row[0])"/>
                    </b-col>
                    <b-col>
                        <ImageCarouselCard v-if="row.length > 1" :imgUrl="row[1]" :contentType="contentTypesChunked[index][1]" @viewImage="deleteMedia(row[1])"/>
                    </b-col>
                </b-row>
            </b-container>
            <b-spinner class="mt-5 mb-3" v-if="uploading == true"/>
            <b-button v-else class="mt-5 mb-3" variant="primary" size="lg" @click="saveMedia">Save Media</b-button>
        </div>
        <div class="bottom-margin-box"></div>
    </div>
</template>

<script>
import ImageCarouselCard from '../../components/project_view/nested/ImageCarouselCard.vue';

import { storage, db } from '../../db';

export default {
    components: {
        ImageCarouselCard,
    },
    props: {
        project: null,
    },
    data() {
        return {
            imgUrls: [],
            contentTypes: [],
            subRefs: [],
            subRefsToDelete: [],
            loading: true,
            uploading: false,
            filesToUpload: [],
        }
    },
    computed: {
        imagesChunked: function() {

            var chunks = [];

            console.log(this.imgUrls.length);

            for (var i=0; i < this.imgUrls.length; i++) {
                if (i % 2 == 0) {
                    chunks.push([]);
                }
                

                chunks[chunks.length-1].push(this.imgUrls[i]);
            }

            console.log(`chunks length: ${chunks.length}`);

            return chunks;
        },
        contentTypesChunked: function() {
            var chunks = [];

            for (var i=0; i < this.contentTypes.length; i++) {
                if (i % 2 == 0) {
                    chunks.push([]);
                }

                chunks[chunks.length-1].push(this.contentTypes[i]);
            }

            return chunks;
        }
    },
    methods: {
        deleteMedia(url) {
            var shouldDelete = confirm("Delete this item from project media?");

            if (!shouldDelete) return;


            var index = this.imgUrls.indexOf(url);

            this.imgUrls.splice(index, 1);
            this.contentTypes.splice(index, 1);
            this.subRefsToDelete.push(this.subRefs[index]);
            this.subRefs.splice(index, 1);
            
        },
        filesChange(files) {
            console.log(files);
            if (files.length > 0) {
                this.filesToUpload = files;
                console.log(this.filesToUpload);
            }
        },
        loadMedia() {
            if (this.project.media == null || this.project.media.length == 0) {
                this.loading = false;
                return;
            }
            storage.ref().child("projects/" + this.project.id + "/other/").listAll()
                .then((result) => {
                    console.log(`result length: ${result.items.length}`);
                    for (var i=0; i < result.items.length; i++) {
                        var subRef = result.items[i];
                        subRef.getDownloadURL().then((subUrl) => {
                            subRef.getMetadata().then((metadata) => {
                                console.log(`metadata type: ${metadata.contentType}`);
                                this.imgUrls.push(subUrl);
                                this.contentTypes.push(metadata.contentType);
                                this.subRefs.push(subRef);
                                console.log(this.contentTypes);
                                this.loading = false;
                            })
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                    alert("Error loading media, please try again.");
                });
        },
        saveMedia() {

            this.uploading = true;

            var totalCount = this.imgUrls.length + this.filesToUpload.length;
            

            var totalAttempted = 0;
            // var totalErrors = 0;

            var media = [];
            for (var i=0; i < totalCount; i++) {
                media.push(`media${i}`);
            }


            db.collection("projects").doc(this.project.id).update({media: media})
            .then(() => {
                console.log("successfully updated project document");
            })
            .catch((err) => {
                console.log(err);
                alert("Error updating project data.");
            });

            for (i=0; i < this.subRefsToDelete.length; i++) {
                this.subRefsToDelete[i].delete();
            }

            if (this.filesToUpload.length == 0) {
                this.$emit("editMediaComplete");
            }

            for (i=0; i < this.filesToUpload.length; i++) {
                var ref = storage.ref(`/projects/${this.project.id}/other/media${i}`);
                ref.put(this.filesToUpload[i])
                .then(() => {
                    console.log("successfully uploaded file");
                    totalAttempted++;
                    if (totalAttempted == this.filesToUpload.length) {
                        this.$emit("editMediaComplete");
                    }
                })
                .catch((err) => {
                    console.log(err);
                    totalAttempted++;
                    // totalErrors++;
                    if (totalAttempted == this.filesToUpload.length) {
                        alert("Error uploading one or more files. Please try again.");
                        this.uploading = false;
                    }
                });
            }
    
        }
    },
    created() {
        this.loadMedia();
        window.scrollTo(0,0);
    }
}
</script>

<style scoped>

</style>